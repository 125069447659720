.animated-gradient{
  background: linear-gradient(45deg, orange, yellow);
  animation: gradient-animation 3s ease infinite;
  @keyframes gradient-animation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  & {
    background-size: 200% 200%;
    background-clip: text;
  }
}

.responsive-iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
}
